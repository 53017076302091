import React, { useState } from 'react'
import Layout from '../components/Layout';
import PaddedLayout from '../components/PaddedLayout';
import "../styles.scss"
export default function Privacy() {
    return (
        <Layout>
            <PaddedLayout>
                <div className="page-wrapper">
            <h1>This page is under development</h1>
            </div>
            </PaddedLayout>
        </Layout>
    )
}
